import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Hero from "../components/hero"
import ArrowRight from "../components/svg/arrowRight"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import Testimonial from "../components/testimonial"
import Cta from "../components/cta"

const ImmuPage = () => (
    <Layout>
        <Seo 
            title="Webflow website development case study" 
            description="Case study of the Webflow website developed by Launch Lab for Immutable."
        />

        <Hero
            h1="Webflow website development case study"  
            h2="Immutable"
        />

        
        <section className="bg-noise immu section-pad-sm">
            <div className="container" id="anchor">
                <div className="row">
                    <StaticImage 
                        src="../images/immutable-site.png"
                        alt="Screens of the Immutable website"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        className="porti-top-img"
                        placeholder="none"
                        backgroundcolor="white"
                        layout="fullWidth"
                        quality="100%"
                    />
                </div>
            </div>

        </section>
        
        <section className="section-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="porti-wrapper">
                        <div className="cols">
                            <div className="col-26">
                                <div className="sticky-porti-menu">
                                    <button onClick={() => scrollTo('#bg')} className="btn">
                                        Background
                                    </button>
                                    <button onClick={() => scrollTo('#work')} className="btn">
                                        The work
                                    </button>
                                    <button onClick={() => scrollTo('#services')} className="btn">
                                        Services
                                    </button>
                                    <button onClick={() => scrollTo('#testimonial')} className="btn">
                                        Testimonial
                                    </button>
                                    <button onClick={() => scrollTo('#project')} className="btn">
                                        View project
                                    </button>
                                </div>
                            </div>
                            <div className="col-67 long-text">
                                <div id="bg" className="porti-text-section">
                                    <h3>Background</h3>
                                    <p>Immutable is a web3 company that provides an NFT minting and trading platform as well as an NFT game development studio. The company started in 2018, in Australia, and has so far raised $280 million from leading venture capital firms.</p>
                                </div>
                                <div id="work" className="porti-text-section">
                                    <h3>The work</h3>
                                    <p>Immutable were embarking on a rebranding exercise which also required changing their current Webflow website and replacing it with a new website that reflected the new brand.</p>
                                    <p>Working closely with the client's design agency in Sydney, Launch Lab turned supplied Figma designs into a responsive Webflow website.</p>
                                    <p>The work included:</p>
                                    <ul>
                                        <li>Developing a responsive Webflow website</li>
                                        <li>Creating custom animations and marquees using Webflow's animation tool</li>
                                        <li>Integrating the Webflow CMS</li>
                                        <li>Developing many different carousel layouts</li>
                                        <li>3rd party API integration</li>
                                        <li>Custom Javascript</li>
                                        <li>Webflow training and support</li>
                                    </ul>
                                </div>
                                <div id="services" className="porti-text-section">
                                    <h4>Services</h4>
                                    <Link to="/webflow-developer/" className="btn btn-lg btn-line">Webflow development</Link>
                                </div>
                                <div id="testimonial" className="porti-text-section">
                                    <h4>Testimonial</h4>
                                    <Testimonial
                                        quote="I had the pleasure of working with Shaun and the Launch Lab team for 2 years in my time as Head of Marketing at Immutable. Shaun quickly embedded himself as part of the team, helping build our new website and then taking on day-to-day CMS management, always applying care and rigour to every task, big and small. I highly recommend Shaun and his team, I can attest to the fact that he will always have the client's best interests in mind and give his honest thoughts and ideas, even if it is at the expense of banking less hours on his end."
                                        name="Alexandra Thompson"
                                        title="Head of Marketing, Immutable"
                                    />
                                </div>
                                <div className="porti-text-section">
                                    <div id="testimonial" className="porti-section-tweets">
                                        <StaticImage 
                                            src="../images/tweet.jpeg"
                                            alt="A tweet which states the brand new website is outstanding"
                                            formats={["AUTO", "WEBP", "AVIF"]}
                                            className="tweet-img"
                                            placeholder="none"
                                            backgroundcolor="white"
                                            layout="fullWidth"
                                            quality="100%"
                                        />
                                        <StaticImage 
                                            src="../images/tweet2.jpeg"
                                            alt="A tweet which states got a dope website"
                                            formats={["AUTO", "WEBP", "AVIF"]}
                                            className="tweet-img"
                                            placeholder="none"
                                            backgroundcolor="white"
                                            layout="fullWidth"
                                            quality="100%"
                                        />
                                        <StaticImage 
                                            src="../images/tweet3.jpeg"
                                            alt="A tweet which states love the new website design"
                                            formats={["AUTO", "WEBP", "AVIF"]}
                                            className="tweet-img"
                                            placeholder="none"
                                            backgroundcolor="white"
                                            layout="fullWidth"
                                            quality="100%"
                                        />
                                        <StaticImage 
                                            src="../images/tweet4.jpeg"
                                            alt="A tweet which states this is what you call winning"
                                            formats={["AUTO", "WEBP", "AVIF"]}
                                            className="tweet-img"
                                            placeholder="none"
                                            backgroundcolor="white"
                                            layout="fullWidth"
                                            quality="100%"
                                        />
                                        <StaticImage 
                                            src="../images/tweet5.jpeg"
                                            alt="A tweet"
                                            formats={["AUTO", "WEBP", "AVIF"]}
                                            className="tweet-img"
                                            placeholder="none"
                                            backgroundcolor="white"
                                            layout="fullWidth"
                                            quality="100%"
                                        />
                                    </div>
                                </div>
                                <div id="project" className="porti-text-section">
                                    <h5>View project</h5>
                                    <p>View the Immutable website.</p>
                                    <a href="https://www.immutable.com/" className="btn btn-lg btn-purple" target="_blank" rel="noopener noreferrer">View website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>
        
        <Cta 
            text="Get an obligation free web design or web development quote from an experienced local Australian development team."
        />
    </Layout>
)

export default ImmuPage